import React from 'react'
import Layout from 'features/energy-gardens/landingpage/components/landing-page-layout/LandingPageLayout'
import { ENERGY_GARDENS } from 'features/energy-gardens/constants'
import { Language } from 'types/language'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { DEFAULT_NAMESPACES } from 'constants/i18n'
import { LandingPageProps } from 'features/energy-gardens/types'
import AboutTheProject from 'features/energy-gardens/landingpage/views/about-the-project/AboutTheProject'
import { energyGardensLib } from 'lib'

const EnergyGardenDetailPage = (props: LandingPageProps) => {
  return (
    <Layout pageTitle="Over dit project" {...props}>
      <AboutTheProject />
    </Layout>
  )
}

export const getStaticProps = async ({ params, locale }) => {
  const { slug } = params
  const energyGarden = await energyGardensLib.getEnergyGardenBySlug(slug)

  return {
    props: {
      energyGarden,
      slug,
      ...(await serverSideTranslations(locale, [...DEFAULT_NAMESPACES]))
    }
  }
}

export const getStaticPaths = async () => {
  const paths = []
  const gardens = Object.keys(ENERGY_GARDENS)

  gardens.forEach((garden) => {
    const gardenPaths = [
      { locale: Language.DUTCH, params: { slug: ENERGY_GARDENS[garden][Language.DUTCH] } }
      // { locale: Language.FRENCH, params: { slug: ENERGY_GARDENS[garden][Language.FRENCH] } }
    ]
    paths.push(...gardenPaths)
  })

  return {
    fallback: 'blocking',
    paths // will server-render pages on-demand if the path does not exist
  }
}

export default EnergyGardenDetailPage
